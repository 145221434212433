import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledMovieMobile = styled.div`
  display: none;
  @media (max-width: 799px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    iframe {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }
`

const FixedButton = styled.button`
  display: none;

  @media (max-width: 799px) and (orientation: portrait) {
    display: block;
    font-weight: bold;
    font-family: "kanit" !important;
    color: black !important;
    z-index: 999;
    position: fixed;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-size: 3vh;
    letter-spacing: inherit;
    top: 0;
    right: 1.5vh;
  }
`

const VideoMobile = props => (
  <StyledMovieMobile>
    <iframe
      id={props.id}
      title={props.title}
      src={props.src}
      autoplay
      frameborder="0"
    ></iframe>
    <FixedButton id="home-link">
      <Link to="/">Back</Link>
    </FixedButton>
  </StyledMovieMobile>
)

export default VideoMobile
