import React from "react"
import { Link } from "gatsby"
import { FadeIn } from "./Animations"

import styled from "styled-components"
import { FixedButton } from "./FixedButton"
const StyledMovie = styled.div`
  display: none;
  @media (min-width: 800px) {
    display: block;
    position: fixed;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    background-color: black;

    opacity: 0;
    animation: ${FadeIn} 1s 1.8s ease forwards;

    h1 {
      color: black !important;
    }

    #portraitmode {
      display: none;
      @media screen and (max-width: 800px) and (orientation: portrait) {
        display: block;
        height: 65vw;
        width: 65vw;
        margin: 25vh auto;
        background-color: white;
        z-index: 999;
      }
    }
    .vimeo-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .vimeo-wrapper iframe {
      width: 100vw;
      height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
      min-height: 100vh;
      min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: black;
    }
  }
`

const Video = props => (
  <StyledMovie>
    <FixedButton>
      <Link to="/">Back</Link>
    </FixedButton>
    <div class="vimeo-wrapper">
      <iframe
        id={props.id}
        title={props.title}
        src={props.src}
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </div>
  </StyledMovie>
)

export default Video
