import React from "react"
import Video from "../components/Video"
import VideoMobile from "../components/VideoMobile"

export default function KanyePage(props) {
  return (
    <div id="kanye-hurricane">
      <VideoMobile
        title="Kanye West, Heaven and Hell • Directed by Arnaud Bresson"
        src="https://player.vimeo.com/video/686596132?title=0&muted=0"
        allowsInlineMediaPlayback="true"
      />
      <Video
        title="Kanye West, Heaven and Hell • Directed by Arnaud Bresson"
        src="https://player.vimeo.com/video/686596132?background=1&autoplay=1&loop=1&byline=0&title=0&muted=0"
      />
    </div>
  )
}
